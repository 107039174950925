<template>
  <div ref="student" class="student">
    <keep-alive>
      <list-template
          :current-page="page"
          :loading="loading"
          :page-size="10"
          :table-config="tableConfig"
          :search-config="searchConfig"
          :table-data="tableData"
          :total="total"
          @onChangePage="changeCurrentPage"
          @onReset="search = {}"
          @onSearch="onSearch"
          @onExportData="onExportData"
      >
      </list-template>
    </keep-alive>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import tools from 'utils/tools'

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data() {
    return {
      loading: false,
      // 表格搜索配置
      searchConfig: [
        {
          prop: 'talk_id',
          placeholder: '筛选谈话任务名称',
          tag: 'select',
          options: [],
          value: 'id',
          label: 'name',
        },
        {
          prop: 'school_id',
          placeholder: '筛选校区',
          tag: 'select',
          options: [],
          value: 'id',
          label: 'school_name',
        },
        {
          prop: 'grade_id',
          placeholder: '筛选年级',
          tag: 'select',
          options: [],
          value: 'id',
          label: 'grade_name',
        },
        {
          prop: 'qualified',
          placeholder: '是否合格',
          tag: 'select',
          options: [{
            value: 0,
            label: '不合格'
          }, {
            value: 1,
            label: '合格'
          }],
        }
      ],
      // 表格配置
      tableConfig: [
        {
          prop: 'talk',
          label: '谈话任务名称'
        },
        {
          prop: 'teacher',
          label: '班主任姓名'
        },
        {
          prop: 'class',
          label: '行政班'
        },
        {
          prop: 'current',
          label: '当前班级人数'
        },
        {
          prop: 'completed',
          label: '已完成人数'
        },
        {
          prop: 'completion_rate',
          label: '完成率'
        },
        {
          prop: 'qualified',
          label: '是否合格'
        },
        {
          prop: 'garde',
          label: '年级'
        },
        {
          prop: 'school',
          label: '校区'
        }
      ],
      search: {},
      // 总数据量
      total: 0,
      // 表格中的数据
      tableData: []
    }
  },
  computed: {
    ...mapState(['page'])
  },
  mounted() {
    this.$store.commit('setPage', 1)
    this.getData()
  },
  methods: {
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit('setPage', e)
      this.getData()
    },
    // 获取数据
    getData() {
      let {search, page} = this
      let params = {
        ...search,
        page
      }
      this.loading = true;
      this.$_axios2.get('api/work/talk/statistics', {params}).then(res => {
        this.tableData = res.data.data.list
        this.total = res.data.data.page.total
      }).finally(() => this.loading = false)
    },
    // 搜索功能
    onSearch(val) {
      this.$store.commit('setPage', 1)
      this.search = val
      this.getData()
    },
    // 表格数据导出
    onExportData() {
      let {search} = this
      let params = {...search}
      this.$_axios2.get('api/work/talk/download', {params}).then(res => {
        tools.download('交心谈话统计', res.data)
      })
    },
  },
  created() {
    this.$_axios2.get('api/sub/substitute/school').then(res => {
      this.searchConfig[1].options = res.data.data
    })
    this.$_axios2.get('api/common/screen/grade-all').then(res => {
      this.searchConfig[2].options = res.data.data
    })
    this.$_axios2.get('api/work/talk/get-list').then(res => {
      this.searchConfig[0].options = res.data.data.list
    })
  }
}
</script>

<style lang="scss" scoped>
.t-top {
  position: relative;
}
</style>
